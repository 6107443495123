import { notification } from 'antd';
import {
    CloseCircleFilled,
    CheckCircleFilled,
    InfoCircleFilled,
} from '@ant-design/icons';
import { localStorageKeys } from '.';

export const baseUrl = process.env.NEXT_PUBLIC_API_BASE;
export const baseUrl_v2 = process.env.NEXT_PUBLIC_API_BASE_V2;
export const loginApiBase = process.env.NEXT_PUBLIC_LOGIN_API_BASE;

const closeIcon = () => {
    return (
        <svg
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.80913 5.9993L9.9107 1.11024C9.97945 1.02899 9.92163 0.905548 9.81538 0.905548H8.56851C8.49507 0.905548 8.42476 0.938361 8.37632 0.994611L4.99351 5.02742L1.6107 0.994611C1.56382 0.938361 1.49351 0.905548 1.41851 0.905548H0.171634C0.065384 0.905548 0.00757144 1.02899 0.0763214 1.11024L4.17788 5.9993L0.0763214 10.8884C0.0609208 10.9065 0.0510407 10.9286 0.0478538 10.9522C0.0446669 10.9757 0.048307 10.9997 0.0583423 11.0213C0.0683776 11.0428 0.0843867 11.061 0.104468 11.0738C0.12455 11.0865 0.147861 11.0932 0.171634 11.093H1.41851C1.49195 11.093 1.56226 11.0602 1.6107 11.004L4.99351 6.97117L8.37632 11.004C8.4232 11.0602 8.49351 11.093 8.56851 11.093H9.81538C9.92163 11.093 9.97945 10.9696 9.9107 10.8884L5.80913 5.9993Z"
                fill="#535353"
            />
        </svg>
    );
};

export const getErrorNotification = (error, msg = '') => {
    notification.destroy();
    notification['error']({
        description:
            error?.message ||
            msg ||
            'Something went wrong, Please try again later',
        className: 'success-notification-style',
        icon: (
            <CloseCircleFilled style={{ color: '#F5222D', fontSize: '16px' }} />
        ),
        closeIcon: closeIcon(),
    });
};

export const getErrorCodeNotification = (response) => {
    let description = 'Something went wrong! Please try again later';
    if (response) {
        description = `${
            response.error_code && response.error_code.length
                ? 'Error: ' + response.error_code
                : ''
        }: ${response.message ? response.message : ''}`;
    }
    notification['error']({
        description,
        className: 'success-notification-style',
        icon: (
            <CloseCircleFilled style={{ color: '#F5222D', fontSize: '16px' }} />
        ),
        closeIcon: closeIcon(),
    });
};

export const getSuccessNotification = (response) => {
    if (response && response.message)
        notification['success']({
            description: response.message,
            icon: (
                <CheckCircleFilled
                    style={{ color: '#52C41A', fontSize: '16px' }}
                />
            ),
            closeIcon: closeIcon(),
            className: 'success-notification-style',
        });
};

export const getInfoNotification = (message) => {
    notification['info']({
        description: message,
        closeIcon: closeIcon(),
        className: 'success-notification-style',
        icon: (
            <InfoCircleFilled
                style={{
                    color: '#1890FF',
                    width: '14px',
                    height: '14px',
                    fontSize: '16px',
                }}
            />
        ),
    });
};

const getToken = (backupToken, enc_order_id, retainUser) => {
    // if we don't want first user to logout use this token
    if (retainUser) {
        return backupToken;
    } else {
        if (localStorage.getItem(localStorageKeys.ACCESS_TOKEN))
            return localStorage.getItem(localStorageKeys.ACCESS_TOKEN);
        else if (backupToken) return backupToken;
        else if (enc_order_id)
            return JSON.parse(sessionStorage.getItem(enc_order_id) || '{}')
                ?.deeplinkToken;
    }
};

export const getHeaders = async (
    requestType,
    endPoint,
    sendAuthHeader,
    responseType,
    params,
    data,
    useLoginApiBase,
    backupToken,
    enc_order_id,
    retainUser,
    version
) => {
    let token = getToken(backupToken, enc_order_id, retainUser);

    let baseURL = baseUrl_v2;

    if (useLoginApiBase) baseURL = loginApiBase;
    else if (version === 'v1') baseURL = baseUrl;

    const baseConfig = {
        baseURL,
    };
    let obj = {
        method: requestType,
        url: endPoint,
        ...baseConfig,
    };

    if (requestType === 'get') {
        obj = {
            ...obj,
            params,
            responseType,
        };
    } else if (requestType === 'post') {
        obj = {
            ...obj,
            responseType,
            data,
        };
    } else if (requestType === 'patch') {
        obj = {
            ...obj,
            responseType,
            data,
        };
    } else if (requestType === 'postWithHtml') {
        obj = {
            ...obj,
            method: 'post',
            data,
        };
    }

    if (sendAuthHeader) {
        obj = {
            ...obj,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    }

    return obj;
};

export const getCatchBlock = (error) => {
    if (
        error &&
        error.response &&
        error.response.status &&
        error.response.status === 401
    ) {
        localStorage.clear();
        sessionStorage.clear();
        setTimeout(() => {
            window.location.href = '/home';
        }, 2000);
        throw { message: 'Un Authorized,redirecting to home...' };
    } else if (error.response && error.response.data) {
        throw { ...error.response.data }; // => the response payload
    } else {
        return error;
    }
};
