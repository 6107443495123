import React, { ReactNode, useCallback } from 'react';
import { Input } from 'antd';
import styles from './input.module.scss';

const CustomInput = ({
    placeholder = '',
    change,
    type,
    value,
    disabled = false,
    prefix = '',
    min = '',
    max = '',
    name = '',
    blur,
    style = {},
    inputType = '',
    addonAfter = '',
    readOnly = false,
}: {
    placeholder?: string;
    change?: Function;
    type?: string;
    value: string;
    disabled?: boolean;
    prefix?: any;
    min?: string;
    max?: string;
    name?: string;
    blur?: Function;
    style?: object;
    inputType?: string;
    addonAfter?: ReactNode;
    readOnly?: boolean;
}) => {
    const checkIfAmount = useCallback(() => {
        return inputType.split('-').includes('amount');
    }, [inputType]);

    const classNameMapping = {
        'variable-amount': styles.variableAmountInput,
        login: styles.loginInputBox,
        inputAddonAfter: styles.inputAddOnBefore,
    };

    const handleInputChange = (event) => {
        if (
            type === 'number' &&
            (event.key === 'e' ||
                event.key === '-' ||
                (!checkIfAmount() && event.key === '.'))
        )
            event.preventDefault();
    };

    return (
        <Input
            readOnly={readOnly}
            placeholder={placeholder}
            onKeyDown={(event) => handleInputChange(event)}
            onChange={(event) => change && change(event)}
            onWheel={(event) => {
                if (type === 'number') event.currentTarget.blur();
            }}
            type={type}
            value={value}
            disabled={disabled}
            prefix={prefix}
            min={min}
            max={max}
            name={name}
            addonAfter={addonAfter}
            className={classNameMapping?.[inputType] || styles.inputBox}
            autoComplete="off"
            onBlur={(event) => blur && blur(event)}
            style={{ ...style }}
        />
    );
};

export default CustomInput;
